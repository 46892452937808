import React from 'react';
import styled from 'styled-components';
import {Title} from "../styled";

const TableWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Table = styled.table`
    border-collapse: collapse;
    width: 60%;
`;

const TableRow = styled.tr``;

const TableCell = styled.td`
    padding: 16px;
    border-bottom: 2px solid red;
    text-align: left;
`;

const Link = styled.a`
    text-decoration: none;
    color: inherit;
    display: block;
    width: 100%;
    height: 100%;
`;

export default function ActionTable({title, links}) {
  return (
    <>
      <Title>{title}</Title>
      <TableWrapper>
        <Table>
          <tbody>
          {links.map((link, index) => (
            <TableRow key={index}>
              <TableCell>
                <Link href={link.href}>{link.label}</Link>
              </TableCell>
            </TableRow>
          ))}
          </tbody>
        </Table>
      </TableWrapper>
    </>
  );
};
