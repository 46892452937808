import React, {useState} from 'react';
import {addIngredient} from "../../api/Ingredient";
import {useNavigate} from "react-router-dom";
import {Container} from "../../packages/my-theme/form/styled";
import {Title} from "../../packages/my-theme/styled";
import Section from "../../packages/my-theme/form/Section";
import TextArea from "../../packages/my-theme/form/TextArea";
import AddButton from "../../packages/my-theme/form/AddButton";
import MessagePopup from "../../packages/my-theme/component/MessagePopup";

export default function NewIngredient() {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [popup, setPopup] = useState({show: false, message: '', success: false});

  const handleSubmit = async () => {
    const ingredientData = {name};

    try {
      const response = await addIngredient(ingredientData);
      if (response.result.status === "success") {
        setPopup({show: true, message: 'Ingrediente aggiunto con successo!', success: true});
      } else {
        throw new Error('Errore durante l\'aggiunta dell\'ingrediente');
      }
    } catch (err) {
      setPopup({show: true, message: 'Errore durante l\'aggiunta dell\'ingrediente', success: false});
    }
  };

  const handleRedirect = () => {
    navigate("/settings/ingredients");
  };

  return (
    <Container>
      <Title>New Ingredient</Title>
      <Section title="">
        <TextArea
          placeholder="Ingredient Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </Section>

      <AddButton type="button" onClick={handleSubmit}>
        Submit
      </AddButton>

      <MessagePopup
        show={popup.show}
        message={popup.message}
        success={popup.success}
        onButtonClick={handleRedirect}
      />
    </Container>
  );
}
