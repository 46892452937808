import React from 'react';
import styled from 'styled-components';
import {useRouteError} from "react-router-dom";
import {FullPhotoBackground} from "../styled";

export default function ErrorPage({children}) {
  const error = useRouteError();
  console.error(error);

  const Container = styled.div`
      align-items: center;
      justify-content: center;
  `;

  return (<FullPhotoBackground>
    <Container>
      {children}
    </Container>
  </FullPhotoBackground>);
}
