import {handleGetRequest, handlePostRequest} from "./handleRequest";
import Ingredient from "../model/Ingredient";

export async function getAllIngredients() {
  try {
    const data = await handleGetRequest(`${process.env.REACT_APP_API_URL}/lb/ingredients/?action=getAllIngredients`);
    return data.map(Ingredient.fromData);
  } catch (error) {
    console.error("Errore durante il recupero degli ingredienti:", error);
    throw error;
  }
}

export async function addIngredient(data) {
  try {
    return await handlePostRequest(`${process.env.REACT_APP_API_URL}/lb/ingredients/`, {
      ingredient: data, action: "newIngredient"
    });
  } catch (error) {
    throw error;
  }
}
