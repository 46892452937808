import React, {useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {addAboutText, getAbout} from "../../api/About";
import LoadingSpinner from "../../packages/my-theme/component/LoadingSpinner";
import {Container} from "../../packages/my-theme/form/styled";
import {Title} from "../../packages/my-theme/styled";
import Section from "../../packages/my-theme/form/Section";
import TextArea from "../../packages/my-theme/form/TextArea";
import AddButton from "../../packages/my-theme/form/AddButton";
import MessagePopup from "../../packages/my-theme/component/MessagePopup";

export default function About() {
  const navigate = useNavigate();
  const [about, setAbout] = useState([]);
  const [popup, setPopup] = useState({show: false, message: '', success: false});
  const [language, setLanguage] = useState("IT");
  const textAreaRefs = useRef([]);

  useEffect(() => {
    getAbout()
      .then(data => {
        setAbout(data);
        textAreaRefs.current = data.map(() => React.createRef());
      })
      .catch(error => {
        console.error("Error fetching about data:", error);
        setPopup({show: true, message: 'Errore nel recupero dei dati!', success: false});
      });

    setLanguage("IT")
  }, []);

  const handleTextChange = (index, newText) => {
    const updatedAbout = [...about];
    updatedAbout[index].text = newText;
    setAbout(updatedAbout);
  };

  const handleSubmit = () => {
    const data = about.map((item, index) => ({
      language, text: item.text, view: 1, orderIndex: index + 1, aboutPhotoId: item.aboutPhotoId
    }));

    addAboutText(data)
      .then(response => {
        if (response.message === "Record inseriti con successo") {
          setPopup({show: true, message: 'Inserimento avvenuto con successo!', success: true});
        }
      })
      .catch(error => {
        console.log(error);
        setPopup({show: true, message: 'Errore durante l\'inserimento!', success: false});
      });
  };

  const handleRedirect = () => {
    navigate('/frontendData');
  };

  if (about.length === 0 || language === null) {
    return <LoadingSpinner/>;
  }

  return (<Container>
    <Title>About</Title>

    {about.map((item, index) => (<Section title="">
      <TextArea
        key={index}
        placeholder="Inserisci testo"
        value={item.text}
        onChange={(e) => handleTextChange(index, e.target.value)}
        ref={textAreaRefs.current[index]}
      />
    </Section>))}

    <AddButton type="button" onClick={handleSubmit}>
      Submit
    </AddButton>
    <MessagePopup
      show={popup.show}
      message={popup.message}
      success={popup.success}
      onButtonClick={handleRedirect}
    />
  </Container>);
}
