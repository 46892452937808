import React from 'react';
import {FaYoutube, FaInstagram, FaFacebookF, FaLinkedinIn} from 'react-icons/fa';

const iconMap = {
  YouTube: <FaYoutube/>, Instagram: <FaInstagram/>, Facebook: <FaFacebookF/>, LinkedIn: <FaLinkedinIn/>
};

export default function SocialIcon({platform}) {
  const Icon = iconMap[platform];
  if (!Icon) {
    throw new Error(`Icona non disponibile per la piattaforma: ${platform}`);
  }
  return Icon;
}
