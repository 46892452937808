import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {addHomeData, getHomeData} from "../../api/Home";
import LoadingSpinner from "../../packages/my-theme/component/LoadingSpinner";
import {Container} from "../../packages/my-theme/form/styled";
import {Title} from "../../packages/my-theme/styled";
import Section from "../../packages/my-theme/form/Section";
import TextArea from "../../packages/my-theme/form/TextArea";
import AddButton from "../../packages/my-theme/form/AddButton";
import MessagePopup from "../../packages/my-theme/component/MessagePopup";

export default function Home() {
  const navigate = useNavigate();
  const [homeText, setHomeText] = useState(null);
  const [popup, setPopup] = useState({show: false, message: '', success: false});

  useEffect(() => {
    getHomeData()
      .then(data => {
        setHomeText(data.text);
      })
      .catch(error => {
        setPopup({show: true, message: 'Errore nel recupero dei dati!', success: false});
      });
  }, []);

  const handleSubmit = () => {
    addHomeData({text: homeText, view: 1, orderIndex: 1, photo: "https://liquorebonito.it/background/bottle.png", photoMobile: "https://example.com/photoMobile.jpg", language: "IT"})
      .then(response => {
        if (response.message === "Record inseriti con successo") {
          setPopup({show: true, message: 'Inserimento avvenuto con successo!', success: true});
        }
      })
      .catch(error => {
        console.log(error);
        setPopup({show: true, message: 'Errore durante l\'inserimento!', success: false});
      });
  };

  const handleRedirect = () => {
    navigate('/frontendData');
  };

  if (homeText == null) {
    return <LoadingSpinner/>;
  }

  return (<Container>
    <Title>Home</Title>
    <Section title="">
      <TextArea
        placeholder="Inserisci testo"
        value={homeText}
        onChange={(e) => setHomeText(e.target.value)}
      />
    </Section>
    <AddButton type="button" onClick={handleSubmit}>
      Submit
    </AddButton>
    <MessagePopup
      show={popup.show}
      message={popup.message}
      success={popup.success}
      onButtonClick={handleRedirect}
    />
  </Container>);
}
