import React from 'react';
import styled from 'styled-components';
import {Title} from "../../packages/my-theme/styled";

const TableWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Table = styled.table`
    border-collapse: collapse;
    width: 60%;
`;

const TableRow = styled.tr`
`;

const TableCell = styled.td`
    padding: 16px;
    border-bottom: 2px solid red;
    text-align: left;
`;

const Link = styled.a`
    text-decoration: none;
    color: inherit;
    display: block;
    width: 100%;
    height: 100%;
`;

export default function FrontendData() {
  return (<>
    <Title>Frontend Data</Title>
    <TableWrapper>
      <Table>
        <tbody>
        <TableRow>
          <TableCell>
            <Link href="/frontendData/home">Home</Link>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Link href="/frontendData/cocktails">Cocktails</Link>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Link href="/frontendData/about">About</Link>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Link href="/frontendData/contact">Contacts</Link>
          </TableCell>
        </TableRow>
        {/*<TableRow>
          <TableCell>
            <Link href="/frontendData/footer">Footer</Link>
          </TableCell>
        </TableRow>*/}
        </tbody>
      </Table>
    </TableWrapper>
  </>);
}