import React from 'react';
import ActionTable from "../../packages/my-theme/component/ActionTable";

const links = [
  { href: "/settings/units", label: "Unità di misura" },
  { href: "/settings/ingredients", label: "Ingredienti" },
];

export default function Settings() {
  return <ActionTable title="Impostazioni" links={links} />;
}
