import {handlePostRequest} from "./handleRequest";

export async function logout() {
  try {
    return await handlePostRequest(`${process.env.REACT_APP_API_URL}/login/`, {
      action: "logout"
    });
  } catch (error) {
    throw error;
  }
}

export function handleLogout() {
  logout()
    .then(data => {
      console.log(data);
      window.location.href = "/login";
    })
    .catch(err => console.log(err));
}