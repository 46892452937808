import {handleGetRequest, handlePostRequest} from "./handleRequest";

export async function getAllCocktails() {
  try {
    return await handleGetRequest(`${process.env.REACT_APP_API_URL}/lb/cocktail/?action=getAllCocktailsCard`);
  } catch (error) {
    throw error;
  }
}

export async function getCocktail(id) {
  try {
    return await handleGetRequest(`${process.env.REACT_APP_API_URL}/lb/cocktail/?action=cocktail&id=${id}`);
  } catch (error) {
    throw error;
  }
}

export async function addCocktail(data) {
  try {
    return await handlePostRequest(`${process.env.REACT_APP_API_URL}/lb/cocktail/`, {
      cocktail: data, action: "new"
    });
  } catch (error) {
    throw error;
  }
}

export async function addCocktailWithPhoto(data, photoFile, photoMobile) {
  try {
    const formData = new FormData();
    formData.append('cocktail', JSON.stringify(data));
    formData.append('action', JSON.stringify('new'));
    formData.append('photo', photoFile);
    formData.append('photoMobile', photoMobile);

    const response = await fetch(`${process.env.REACT_APP_API_URL}/lb/cocktail/`, {
      method: 'POST', body: formData, credentials: 'include'
    });

    if (!response.ok) {
      throw new Error('Network response was not ok ' + response.statusText);
    }

    /*const result = await response.text();
    console.log(result);
    return result.data;*/

    const result = await response.json();
    return result.data;
  } catch (error) {
    throw error;
  }
}

export async function updateCocktailWithPhoto(data, photoFile, photoMobile) {
  try {
    const formData = new FormData();
    formData.append('cocktail', JSON.stringify(data));
    formData.append('action', JSON.stringify('update'));
    formData.append('photo', photoFile);
    formData.append('photoMobile', photoMobile);

    const response = await fetch(`${process.env.REACT_APP_API_URL}/lb/cocktail/`, {
      method: 'POST', body: formData, credentials: 'include'
    });

    if (!response.ok) {
      throw new Error('Network response was not ok ' + response.statusText);
    }

    /*const result = await response.text();
    console.log(result);
    return result.data;*/

    const result = await response.json();
    return result.data;
  } catch (error) {
    throw error;
  }
}


export async function getHomeCocktailsIndex() {
  try {
    return await handleGetRequest(`${process.env.REACT_APP_API_URL}/lb/cocktail/?action=getHomeCocktailIndex`);
  } catch (error) {
    throw error;
  }
}

export async function updateVisualizzato(data) {
  try {
    return await handlePostRequest(`${process.env.REACT_APP_API_URL}/lb/cocktail/`, {
      cocktail: data, action: "updateVisualized"
    });
  } catch (error) {
    throw error;
  }
}

export async function deleteCocktail(data) {
  try {
    return await handlePostRequest(`${process.env.REACT_APP_API_URL}/lb/cocktail/`, {
      id: data, action: "delete"
    });
  } catch (error) {
    throw error;
  }
}

export async function getCocktailText() {
  try {
    return await handleGetRequest(`${process.env.REACT_APP_API_URL}/lb/cocktail/cocktailText.php/?action=get`);
  } catch (error) {
    throw error;
  }
}

export async function addCocktailTextData(data) {
  try {
    return await handlePostRequest(`${process.env.REACT_APP_API_URL}/lb/cocktail/cocktailText.php`, {
      data: data, action: "new"
    });
  } catch (error) {
    throw error;
  }
}
