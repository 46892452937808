import {handleGetRequest, handlePostRequest} from "./handleRequest";
import UnitMeasure from "../model/UnitMeasure";

export async function getAllUnitOfMeasure() {
  try {
    const response = await handleGetRequest(`${process.env.REACT_APP_API_URL}/lb/unitMeasure/?action=getAllUnitMisure`);

    return response.map(item => new UnitMeasure(item.id_unit, item.unit_name, item.unit_symbol));
  } catch (error) {
    throw error;
  }
}

export async function addUnitOfMeasure(data) {
  try {
    return await handlePostRequest(`${process.env.REACT_APP_API_URL}/lb/unitMeasure/`, {
      unit: data, action: "newUnitMisure"
    });
  } catch (error) {
    throw error;
  }
}
