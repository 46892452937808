import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {addFooterData, getFooterData} from "../../api/Footer";
import LoadingSpinner from "../../packages/my-theme/component/LoadingSpinner";
import {Container} from "../../packages/my-theme/form/styled";
import {Title} from "../../packages/my-theme/styled";
import Section from "../../packages/my-theme/form/Section";
import TextArea from "../../packages/my-theme/form/TextArea";
import AddButton from "../../packages/my-theme/form/AddButton";
import MessagePopup from "../../packages/my-theme/component/MessagePopup";

export default function Footer() {
  const navigate = useNavigate();
  const [footerText, setFooterText] = useState(null);
  const [popup, setPopup] = useState({show: false, message: '', success: false});

  useEffect(() => {
    getFooterData()
      .then(data => {
        setFooterText(data[0].text);
      })
      .catch(error => {
        console.error("Error fetching footer data:", error);
        setPopup({show: true, message: 'Errore nel recupero dei dati!', success: false});
      });
  }, []);

  const handleSubmit = () => {
    addFooterData({text: footerText, view: 1, orderIndex: 1})
      .then(response => {
        if (response.message === "Record FooterData inseriti con successo") {
          setPopup({show: true, message: 'Inserimento avvenuto con successo!', success: true});
        }
      })
      .catch(error => {
        console.log(error);
        setPopup({show: true, message: 'Errore durante l\'inserimento!', success: false});
      });
  };

  const handleRedirect = () => {
    navigate('/frontendData');
  };

  if (footerText == null) {
    return <LoadingSpinner/>;
  }

  return (<Container>
    <Title>Footer</Title>
    <Section title="Dettagli Footer">
      <TextArea
        placeholder="Inserisci testo"
        value={footerText}
        onChange={(e) => setFooterText(e.target.value)}
      />
    </Section>
    <AddButton type="button" onClick={handleSubmit}>
      Submit
    </AddButton>
    <MessagePopup
      show={popup.show}
      message={popup.message}
      success={popup.success}
      onButtonClick={handleRedirect}
    />
  </Container>);
}
