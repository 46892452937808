import styled from "styled-components";

export const fontSize = '16px';

export const Container = styled.div`
    padding: 20px;
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
`;

export const InputField = styled.input`
    display: block;
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    height: 40px;
    box-sizing: border-box;
    font-size: ${fontSize};
    color: white;
    border: none;
    border-bottom: 2px solid red;
    background: transparent;
    outline: none;
`;
