import React, {useEffect, useState} from 'react';
import {deleteCocktail, getAllCocktails, getHomeCocktailsIndex, updateVisualizzato} from '../../api/Cocktail';
import {Link, useNavigate} from 'react-router-dom';
import {FaEdit, FaTrash} from 'react-icons/fa';
import styled from 'styled-components';
import LoadingSpinner from "../../packages/my-theme/component/LoadingSpinner";

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
`;

const TableHeader = styled.th`
    padding: 10px;
    border-bottom: 1px solid #ddd;
`;

const TableCell = styled.td`
    padding: 10px;
    border-bottom: 1px solid #ddd;
`;

const CenteredTableCell = styled(TableCell)`
    text-align: center;
    vertical-align: middle;
`;

const Select = styled.select`
    margin: auto;
    display: block;
    border-radius: 8px;
    padding: 5px;
    border: 1px solid #ccc;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    text-align: center;
    text-align-last: center;
    appearance: none;

    option {
        background-color: rgba(0, 0, 0, 0.6);
        color: white;
        text-align: center;
    }

    &:hover {
        background-color: rgba(0, 0, 0, 0.8);
    }
`;

const TrashIcon = styled(FaTrash)`
    cursor: pointer;
    color: red;
    margin: auto;
    display: block;
`;

const CocktailLink = styled(Link)`
    color: white;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`;

const NewButton = styled.button`
    background-color: #FF2E2E;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    margin-bottom: 20px;

    &:hover {
        background-color: darkred;
    }
`;

const ButtonContainer = styled.div`
    text-align: right;
    margin-bottom: 20px;
`;

const EditIcon = styled(FaEdit)`
    cursor: pointer;
    color: blue;

    &:hover {
        color: darkblue;
    }
`;

export default function Cocktails() {
  const [cocktails, setCocktails] = useState(null);
  const [, setSelectedStates] = useState({});
  const [homeCocktailsIndex, setHomeCocktailsIndex] = useState(null);
  const [, setHomeIndexValues] = useState({});
  const navigate = useNavigate(); // Hook per la navigazione

  useEffect(() => {

    getAllCocktails()
      .then(data => {
        setCocktails(data);
      })
      .catch(() => {
        // Gestione dell'errore
      });

    getHomeCocktailsIndex()
      .then(data => {
        setHomeCocktailsIndex(data);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  const handleStateChange = (idCocktail, newState) => {
    setSelectedStates(prevStates => ({
      ...prevStates, [idCocktail]: newState,
    }));

    const visualizzatoState = (newState === "Visualizzato") ? 1 : 0;

    updateVisualizzato({
      id: idCocktail, state: visualizzatoState,
    })
      .then(() => {
        setCocktails(prevCocktails => prevCocktails.map(cocktail => cocktail.idCocktail === idCocktail ? {
          ...cocktail,
          visualizzato: visualizzatoState === 1
        } : cocktail));
      })
      .catch(error => {
        console.log(error);
      });
  };

  const handleRemove = (idCocktail) => {
    deleteCocktail(idCocktail)
      .then(data => {
        setCocktails(prevCocktails => prevCocktails.filter(cocktail => cocktail.idCocktail !== idCocktail));
      })
      .catch(err => {
        console.error("Errore durante l'eliminazione del record:", err);
      });
  };

  if (cocktails == null || homeCocktailsIndex == null) {
    return <LoadingSpinner/>;
  }

  return (<>
    <h1>Cocktails</h1>
    <ButtonContainer>
      <NewButton onClick={() => navigate('/addCocktail')}>Nuovo</NewButton>
    </ButtonContainer>
    <Table>
      <thead>
      <tr>
        <TableHeader>Nome</TableHeader>
        <TableHeader>Stato</TableHeader>
        <TableHeader>Edit</TableHeader>
        <TableHeader>Rimuovi</TableHeader>
      </tr>
      </thead>
      <tbody>
      {cocktails.map(cocktail => {
        const homeIndexItem = homeCocktailsIndex.find(item => parseInt(item.cocktail) === cocktail.idCocktail);
        return (<tr key={cocktail.idCocktail}>
          <TableCell>
            <CocktailLink to={`/cocktail/${cocktail.idCocktail}`}>
              {cocktail.nomeCocktail}
            </CocktailLink>
          </TableCell>
          <CenteredTableCell>
            <Select
              value={cocktail.visualizzato === false ? 'Non Visualizzato' : 'Visualizzato'}
              onChange={(e) => handleStateChange(cocktail.idCocktail, e.target.value)}
            >
              <option value="Non Visualizzato">Non Visualizzato</option>
              <option value="Visualizzato">Visualizzato</option>
            </Select>
          </CenteredTableCell>
          <CenteredTableCell>
            <EditIcon onClick={() => navigate(`/cocktail/${cocktail.idCocktail}`)}/>
          </CenteredTableCell>
          <CenteredTableCell>
            <TrashIcon onClick={() => handleRemove(cocktail.idCocktail)}/>
          </CenteredTableCell>
        </tr>);
      })}
      </tbody>
    </Table>
  </>);
}
