import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {getAllUnitOfMeasure} from "../../api/UnitMeasure";
import AsyncSelect from 'react-select/async';
import {getAllIngredients} from "../../api/Ingredient";
import {getSocialNames} from "../../api/Social";
import {addCocktailWithPhoto} from "../../api/Cocktail";
import {useNavigate} from "react-router-dom";
import LoadingSpinner from "../../packages/my-theme/component/LoadingSpinner";
import {Container} from "../../packages/my-theme/form/styled";
import {Title} from "../../packages/my-theme/styled";
import Section from "../../packages/my-theme/form/Section";
import TextArea from "../../packages/my-theme/form/TextArea";
import AddButton from "../../packages/my-theme/form/AddButton";
import SocialIcon from "../../packages/my-theme/component/SocialIcons";
import MessagePopup from "../../packages/my-theme/component/MessagePopup";


const fontSize = '16px';

const IngredientWrapper = styled.div`
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
    align-items: center;
    height: 40px;
`;

const QuantityWrapper = styled.div`
    display: flex;
    flex: 1;
    gap: 10px;
    align-items: center;
`;

const QuantityInput = styled.input`
    flex: 2;
    padding: 8px;
    height: 40px;
    box-sizing: border-box;
    font-size: ${fontSize};
    color: white;
    border: none;
    border-bottom: 2px solid red;
    background: #1e1e1e;
    outline: none;
`;

const UnitSelect = styled.select`
    flex: 1;
    padding: 8px;
    height: 40px;
    box-sizing: border-box;
    font-size: ${fontSize};
    color: white;
    border: none;
    border-bottom: 2px solid red;
    background: #1e1e1e;
    outline: none;
`;

const RemoveButton = styled.button`
    background-color: red;
    color: white;
    padding: 5px 10px;
    border: none;
    cursor: pointer;
    height: 40px;
    font-size: ${fontSize};
    border-bottom: 2px solid red;
    outline: none;
`;

const SocialWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
`;

const SocialInput = styled.input`
    margin-left: 10px;
    padding: 8px;
    width: 100%;
    box-sizing: border-box;
    font-size: ${fontSize};
    color: white;
    border: none;
    border-bottom: 2px solid red;
    background: transparent;
    outline: none;
`;

const FileInput = styled.input`
    margin: 10px 0;
    border: 1px solid red;
    padding: 8px;
    color: white;
    background: #1e1e1e;
`;

export default function AddCocktail() {
  const navigate = useNavigate();
  const [status, setStatus] = useState(null);
  const [title, setTitle] = useState('');
  const [shortDescription, setShortDescription] = useState('');
  const [longDescription, setLongDescription] = useState('');
  const [ingredients, setIngredients] = useState([{name: '', quantity: '', unit: ''}]);
  const [process, setProcess] = useState('');
  const [unitOptions, setUnitOptions] = useState([]);
  const [ingredientOptions, setIngredientOptions] = useState([]);
  const [socials, setSocials] = useState([]);
  const [socialLinks, setSocialLinks] = useState({});
  const [popup, setPopup] = useState({show: false, message: '', success: false});
  const [photo, setPhoto] = useState(null);
  const [photoMobile, setPhotoMobile] = useState(null);

  useEffect(() => {
    getAllUnitOfMeasure()
      .then(data => {
        setUnitOptions(data);
      })
      .catch(err => {
        console.error("Errore durante il recupero delle unità di misura:", err);
      });

    getAllIngredients()
      .then(data => {
        const options = data
          .map(ingredient => ({
            label: ingredient.name, value: ingredient.id,
          }))
          .sort((a, b) => a.label.localeCompare(b.label));
        setIngredientOptions(options);
      })
      .catch(err => {
        console.error("Errore durante il recupero degli ingredienti:", err);
      });

    getSocialNames()
      .then(data => {
        setSocials(data);
        setSocialLinks(data.reduce((acc, social) => ({...acc, [social]: ''}), {}));
        setStatus(true);
      })
      .catch(err => {
        console.error("Errore durante il recupero dei social ", err);
      });
  }, []);

  const handleAddIngredient = () => {
    setIngredients([...ingredients, {name: '', quantity: '', unit: ''}]);
  };

  const handleRemoveIngredient = (index) => {
    const newIngredients = ingredients.filter((_, i) => i !== index);
    setIngredients(newIngredients);
  };

  const handleIngredientChange = (index, field, value) => {
    const newIngredients = [...ingredients];

    if (field === 'unit') {
      const selectedUnit = unitOptions.find(option => parseInt(option.id) === parseInt(value));
      newIngredients[index] = {...newIngredients[index], unit: selectedUnit.id};
    } else {
      newIngredients[index] = {...newIngredients[index], [field]: value};
    }
    setIngredients(newIngredients);
  };

  const filterIngredients = (inputValue) => {
    return ingredientOptions.filter(ingredient => ingredient.label.toLowerCase().includes(inputValue.toLowerCase()));
  };

  const loadIngredientOptions = (inputValue, callback) => {
    setTimeout(() => {
      callback(filterIngredients(inputValue));
    }, 1000);
  };

  const handleSocialLinkChange = (platform, value) => {
    setSocialLinks({...socialLinks, [platform]: value});
  };

  const handlePhotoChange = (e) => {
    setPhoto(e.target.files[0]); // Aggiorna lo stato con il file selezionato
  };

  const handlePhotoMobileChange = (e) => {
    setPhotoMobile(e.target.files[0]); // Aggiorna lo stato con il file selezionato
  };

  const handleSubmit = async () => {
    const cocktailData = {
      title, shortDescription, longDescription, process, ingredients: ingredients.map((ingredient) => ({
        name: ingredient.name, quantity: ingredient.quantity, unit: ingredient.unit,
      })), socialLinks,
    };

    try {
      const data = await addCocktailWithPhoto(cocktailData, photo, photoMobile);

      if (data.message === 'Cocktail inserito con successo') {
        setPopup({show: true, message: 'Cocktail inserito con successo!', success: true});
      } else {
        throw new Error('Errore durante l\'inserimento del cocktail');
      }
    } catch (error) {
      console.error(error);
      setPopup({show: true, message: 'Errore durante l\'inserimento!', success: false});
    }
  };

  const handleRedirect = () => {
    navigate('/cocktails');
  };

  if (!status) {
    return <LoadingSpinner/>;
  }

  return (
    <Container>
      <Title>New Cocktail</Title>
      <Section title="Cocktail Details">
        <TextArea
          placeholder="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        {/*<TextArea
          placeholder="Short Description"
          value={shortDescription}
          onChange={(e) => setShortDescription(e.target.value)}
        />*/}
        <TextArea
          placeholder="Description"
          value={longDescription}
          onChange={(e) => setLongDescription(e.target.value)}
        />
      </Section>

      <Section title="Ingredients">
        {ingredients.map((ingredient, index) => (
          <IngredientWrapper key={index}>
            <AsyncSelect
              cacheOptions
              loadOptions={loadIngredientOptions}
              defaultOptions={ingredientOptions}
              value={ingredient.name ? {
                label: ingredient.name, value: ingredientOptions.find(option => option.label === ingredient.name)?.value
              } : null}
              onChange={(option) => handleIngredientChange(index, 'name', option ? option.label : '')}
              placeholder="Ingredient Name"
              styles={{
                control: (provided) => ({
                  ...provided,
                  minHeight: '40px',
                  border: 'none',
                  borderBottom: '2px solid red',
                  background: '#1e1e1e',
                  boxSizing: 'border-box',
                  fontSize: fontSize,
                  color: 'white',
                }),
                menu: (provided) => ({
                  ...provided, fontSize: fontSize, color: 'white', background: '#1e1e1e', /* Modificato */
                }),
                menuList: (provided) => ({
                  ...provided, fontSize: fontSize, color: 'white', background: '#1e1e1e', /* Modificato */
                }),
                option: (provided) => ({
                  ...provided, fontSize: fontSize, color: 'white', background: '#1e1e1e', /* Modificato */
                }),
                singleValue: (provided) => ({
                  ...provided, color: 'white',
                }),
              }}
            />
            <QuantityWrapper>
              <QuantityInput
                type="number"
                placeholder="Quantity"
                value={ingredient.quantity}
                onChange={(e) => handleIngredientChange(index, 'quantity', e.target.value)}
              />
              <UnitSelect
                value={ingredient.unit || ""}
                onChange={(e) => handleIngredientChange(index, 'unit', e.target.value)}
              >
                <option value="">Select Unit</option>
                {unitOptions.map((unit) => (
                  <option key={unit.id} value={unit.id}>
                    {unit.symbol ? unit.symbol : unit.name}
                  </option>
                ))}
              </UnitSelect>
            </QuantityWrapper>
            {ingredients.length > 1 && (
              <RemoveButton type="button" onClick={() => handleRemoveIngredient(index)}>Remove</RemoveButton>
            )}
          </IngredientWrapper>
        ))}
        <AddButton type="button" onClick={handleAddIngredient}>Add Ingredient</AddButton>
      </Section>

      <Section title="Process">
        <TextArea
          placeholder="Process"
          value={process}
          onChange={(e) => setProcess(e.target.value)}
        />
      </Section>

      <Section title="Social Links">
        {socials.map((social) => (
          <SocialWrapper key={social}>
            <SocialIcon platform={social}/>
            <SocialInput
              type="text"
              placeholder={`Link for ${social}`}
              value={socialLinks[social] || ''}
              onChange={(e) => handleSocialLinkChange(social, e.target.value)}
            />
          </SocialWrapper>
        ))}
      </Section>

      <Section title={"Photo Desktop"}>
        <FileInput type="file" accept="image/*" onChange={handlePhotoChange}/>
      </Section>

      <Section title={"Photo Mobile"}>
        <FileInput type="file" accept="image/*" onChange={handlePhotoMobileChange}/>
      </Section>

      <AddButton type="button" onClick={handleSubmit}>Submit</AddButton>

      <MessagePopup
        show={popup.show}
        message={popup.message}
        success={popup.success}
        onButtonClick={handleRedirect}
      />
    </Container>
  );
}
