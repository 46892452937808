import React from 'react';
import styled from 'styled-components';

const fontSize = '16px'; // Definisci qui il valore di fontSize

const StyledButton = styled.button`
    height: 40px;
    font-size: ${fontSize};
    color: white;
    border: none;
    background: transparent;
    border-bottom: 2px solid red;
    cursor: pointer;
    outline: none;
`;

function AddButton({ type = "button", onClick, children }) {
  return (
    <StyledButton type={type} onClick={onClick}>
      {children}
    </StyledButton>
  );
}

export default AddButton;
