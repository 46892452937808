import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {handlePostRequest} from "../api/handleRequest";
import fetchToken from "../api/fetchToken";
import ThemeProviderComponent from "../packages/my-theme/ThemeProvider";
import {FullPhotoBackground, GrayContainer, Title} from "../packages/my-theme/styled";

const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 300px;
    margin: 0 auto;
`;

const Field = styled.div`
    width: 100%;
    margin-bottom: 1rem;

    label {
        display: block;
        margin-bottom: 0.5rem;
        font-weight: bold;
        color: #FF2E2E;
        text-align: left;
    }

    input[type="text"],
    input[type="password"] {
        width: 100%;
        padding: 0.5rem;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-size: 1rem;
        color: #333;
    }

    input[type="checkbox"] {
        margin-right: 0.5rem;
    }
`;

const SubmitButton = styled.button`
    background-color: #007bff;
    color: white;
    padding: 0.7rem 1.5rem;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #0056b3;
    }
`;

const ErrorMessage = styled.p`
    color: red;
    font-weight: bold;
`;

const SuccessMessage = styled.p`
    color: green;
    font-weight: bold;
`;

export default function LoginForm() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [remember, setRemember] = useState(false);
  const [token, setToken] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    fetchToken()
      .then(data => {
        console.log(data.token);
        setToken(data.token);
      })
      .catch(err => console.error('Failed to fetch token', err));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setError('');
    setMessage('');
    try {
      handlePostRequest(`${process.env.REACT_APP_API_URL}/login/`, {
        username: username, password: password, remember: remember, token: token, action: "login"
      })
        .then(data => {
          /*console.log(data);*/
          if (data.message === 'Login successful') {
            window.location.href = "/";
          } else {
            setError(data.message);
          }
        })
        .catch(err => {
          console.error('Error:', err);
          setError('An unexpected error occurred');
        });

    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ThemeProviderComponent>
      <FullPhotoBackground>
        <GrayContainer>
          <Title>Liquore Bonito </Title>
          <StyledForm onSubmit={handleSubmit}>
            <Field className="field">
              <label htmlFor="username">Username</label>
              <input
                type="text"
                name="username"
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                autoComplete="off"
                required
              />
            </Field>
            <Field className="field">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                name="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="off"
                required
              />
            </Field>
            <Field className="field">
              <label htmlFor="remember">
                <input
                  type="checkbox"
                  name="remember"
                  id="remember"
                  checked={remember}
                  onChange={() => setRemember(!remember)}
                />
                Remember me
              </label>
            </Field>
            <input type="hidden" name="token" value={token}/>
            <SubmitButton type="submit">Login</SubmitButton>
          </StyledForm>
          {error && <ErrorMessage>{error}</ErrorMessage>}
          {message && <SuccessMessage>{message}</SuccessMessage>}
        </GrayContainer>
      </FullPhotoBackground>
    </ThemeProviderComponent>
  );
}
