import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {getAllUnitOfMeasure} from "../../api/UnitMeasure";
import AsyncSelect from 'react-select/async';
import {getAllIngredients} from "../../api/Ingredient";
import {getSocialNames} from "../../api/Social";
import {useNavigate, useParams} from "react-router-dom";
import LoadingSpinner from "../../packages/my-theme/component/LoadingSpinner";
import {Container} from "../../packages/my-theme/form/styled";
import {Title} from "../../packages/my-theme/styled";
import Section from "../../packages/my-theme/form/Section";
import TextArea from "../../packages/my-theme/form/TextArea";
import AddButton from "../../packages/my-theme/form/AddButton";
import SocialIcon from "../../packages/my-theme/component/SocialIcons";
import MessagePopup from "../../packages/my-theme/component/MessagePopup";
import {getCocktail, updateCocktailWithPhoto} from "../../api/Cocktail";

// Stili per il componente
const fontSize = '16px';

const IngredientWrapper = styled.div`
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
    align-items: center;
    height: 40px;
`;

const QuantityWrapper = styled.div`
    display: flex;
    flex: 1;
    gap: 10px;
    align-items: center;
`;

const QuantityInput = styled.input`
    flex: 2;
    padding: 8px;
    height: 40px;
    box-sizing: border-box;
    font-size: ${fontSize};
    color: white;
    border: none;
    border-bottom: 2px solid red;
    background: #1e1e1e;
    outline: none;
`;

const UnitSelect = styled.select`
    flex: 1;
    padding: 8px;
    height: 40px;
    box-sizing: border-box;
    font-size: ${fontSize};
    color: white;
    border: none;
    border-bottom: 2px solid red;
    background: #1e1e1e;
    outline: none;
`;

const RemoveButton = styled.button`
    background-color: red;
    color: white;
    padding: 5px 10px;
    border: none;
    cursor: pointer;
    height: 40px;
    font-size: ${fontSize};
    border-bottom: 2px solid red;
    outline: none;
`;

const SocialWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
`;

const SocialInput = styled.input`
    margin-left: 10px;
    padding: 8px;
    width: 100%;
    box-sizing: border-box;
    font-size: ${fontSize};
    color: white;
    border: none;
    border-bottom: 2px solid red;
    background: transparent;
    outline: none;
`;

const FileInput = styled.input`
    margin: 10px 0;
    border: 1px solid red;
    padding: 8px;
    color: white;
    background: #1e1e1e;
`;

export default function EditCocktail() {
  const {cocktailId} = useParams();
  const navigate = useNavigate();
  const [status, setStatus] = useState(false);
  const [title, setTitle] = useState('');
  const [shortDescription, setShortDescription] = useState('');
  const [longDescription, setLongDescription] = useState('');
  const [ingredients, setIngredients] = useState([]);
  const [process, setProcess] = useState('');
  const [unitOptions, setUnitOptions] = useState([]);
  const [ingredientOptions, setIngredientOptions] = useState([]);
  const [socials, setSocials] = useState([]);
  const [socialLinks, setSocialLinks] = useState({});
  const [popup, setPopup] = useState({show: false, message: '', success: false});
  const [photo, setPhoto] = useState(null);
  const [photoMobile, setPhotoMobile] = useState(null);
  const [photoLink, setPhotoLink] = useState('');
  const [photoMobileLink, setPhotoMobileLink] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const cocktail = JSON.parse(await getCocktail(cocktailId));

        setTitle(cocktail.nomeCocktail);
        setShortDescription(cocktail.descrizioneBreve);
        setLongDescription(cocktail.descrizioneCocktail);
        setIngredients(cocktail.ingredienti.map(ingredient => ({
          name: ingredient.nome_ingredient, quantity: ingredient.quantity, unit: ingredient.unit,
        })));

        setProcess(cocktail.processoProduttivo.map(step => step.descrizione).join("\n"));
        setSocialLinks(cocktail.social.reduce((acc, social) => ({
          ...acc, [social.social_platform]: social.url_social
        }), {}));

        setPhotoLink(cocktail.urlImgCocktail);
        setPhotoMobileLink(cocktail.urlImgCocktailMobile);

        setStatus(true);
      } catch (err) {
        console.error("Errore durante il recupero dei dati del cocktail:", err);
      }
    };

    const fetchAllData = async () => {
      try {
        const [units, ingredientsData, socialNames] = await Promise.all([getAllUnitOfMeasure(), getAllIngredients(), getSocialNames(),]);
        setUnitOptions(units);
        setIngredientOptions(ingredientsData.map(ingredient => ({
          label: ingredient.name, value: ingredient.id,
        })));
        setSocials(socialNames);
        await fetchData();

      } catch (err) {
        console.error("Errore durante il recupero delle unità, ingredienti o social:", err);
      }
    };

    fetchAllData();
  }, [cocktailId]);

  const filterIngredients = (inputValue) => {
    return ingredientOptions.filter(ingredient => ingredient.label.toLowerCase().includes(inputValue.toLowerCase()));
  };

  const loadIngredientOptions = (inputValue, callback) => {
    setTimeout(() => {
      callback(filterIngredients(inputValue));
    }, 1000);
  };

  const handleSocialLinkChange = (platform, value) => {
    setSocialLinks({...socialLinks, [platform]: value});
  };

  const handlePhotoChange = (e) => {
    setPhoto(e.target.files[0]); // Aggiorna lo stato con il file selezionato
  };

  const handlePhotoMobileChange = (e) => {
    setPhotoMobile(e.target.files[0]); // Aggiorna lo stato con il file selezionato
  };

  const handleIngredientChange = (index, field, value) => {
    const newIngredients = [...ingredients];

    if (field === 'unit') {
      const selectedUnit = unitOptions.find(option => parseInt(option.id) === parseInt(value));
      newIngredients[index] = {...newIngredients[index], unit: selectedUnit.id};
    } else {
      newIngredients[index] = {...newIngredients[index], [field]: value};
    }
    setIngredients(newIngredients);

  };

  const handleAddIngredient = () => {
    setIngredients([...ingredients, {name: '', quantity: '', unit: ''}]);
  };

  const handleRemoveIngredient = (index) => {
    const newIngredients = ingredients.filter((_, i) => i !== index);
    setIngredients(newIngredients);
  };

  const handleSubmit = async () => {
    const cocktailData = {
      id: cocktailId,
      title,
      shortDescription,
      longDescription,
      process,
      ingredients: ingredients.map((ingredient) => ({
        name: ingredient.name,
        quantity: ingredient.quantity,
        unit: unitOptions.find(option => option.name === ingredient.unit)?.id || null,
      })),
      socialLinks,
    };

    try {
      const data = await updateCocktailWithPhoto(cocktailData, photo, photoMobile);

      if (data.message === 'Cocktail inserito con successo') {
        setPopup({show: true, message: 'Cocktail aggiornato con successo!', success: true});
      } else {
        throw new Error('Errore durante l\'aggiornamento del cocktail');
      }
    } catch (error) {
      console.error(error);
      setPopup({show: true, message: 'Errore durante l\'aggiornamento!', success: false});
    }
  };

  const handleRedirect = () => {
    navigate('/cocktails');
  };

  if (!status && ingredients.length === 0 && unitOptions.length === 0) {
    return <LoadingSpinner/>;
  }

  return (<Container>
    <Title>Modifica Cocktail</Title>

    <Section title="Cocktail Details">
      <TextArea
        placeholder="Title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <TextArea
        placeholder="Description"
        value={longDescription}
        onChange={(e) => setLongDescription(e.target.value)}
      />
    </Section>

    <Section title="Ingredients">
      {ingredients.map((ingredient, index) => (<IngredientWrapper key={index}>
        <AsyncSelect
          cacheOptions
          loadOptions={loadIngredientOptions}
          defaultOptions={ingredientOptions}
          value={ingredient.name ? {
            label: ingredient.name, value: ingredientOptions.find(option => option.label === ingredient.name)?.value
          } : null}
          onChange={(option) => handleIngredientChange(index, 'name', option ? option.label : '')}
          placeholder="Ingredient Name"
          styles={{
            control: (provided) => ({
              ...provided,
              minHeight: '40px',
              border: 'none',
              borderBottom: '2px solid red',
              background: '#1e1e1e',
              boxSizing: 'border-box',
              fontSize: fontSize,
              color: 'white',
            }), menu: (provided) => ({
              ...provided, fontSize: fontSize, color: 'white', background: '#1e1e1e', /* Modificato */
            }), menuList: (provided) => ({
              ...provided, fontSize: fontSize, color: 'white', background: '#1e1e1e', /* Modificato */
            }), option: (provided) => ({
              ...provided, fontSize: fontSize, color: 'white', background: '#1e1e1e', /* Modificato */
            }), singleValue: (provided) => ({
              ...provided, color: 'white',
            }),
          }}
        />
        <QuantityWrapper>
          <QuantityInput
            type="number"
            placeholder="Quantity"
            value={ingredient.quantity}
            onChange={(e) => handleIngredientChange(index, 'quantity', e.target.value)}
          />
          <UnitSelect
            value={ingredient.unit || ""}
            onChange={(e) => handleIngredientChange(index, 'unit', e.target.value)}
          >
            <option value="">Select Unit</option>
            {unitOptions.map((unit) => (<option key={unit.id} value={unit.name}>
              {unit.symbol ? unit.symbol : unit.name}
            </option>))}
          </UnitSelect>
        </QuantityWrapper>
        {ingredients.length > 1 && (
          <RemoveButton type="button" onClick={() => handleRemoveIngredient(index)}>Remove</RemoveButton>)}
      </IngredientWrapper>))}
      <AddButton type="button" onClick={handleAddIngredient}>Add Ingredient</AddButton>
    </Section>

    <Section title="Process">
      <TextArea
        placeholder="Process"
        value={process}
        onChange={(e) => setProcess(e.target.value)}
      />
    </Section>

    <Section title="Social Links">
      {socials.map((social) => (<SocialWrapper key={social}>
        <SocialIcon platform={social}/>
        <SocialInput
          type="text"
          placeholder={`Link for ${social}`}
          value={socialLinks[social] || ''}
          onChange={(e) => handleSocialLinkChange(social, e.target.value)}
        />
      </SocialWrapper>))}
    </Section>

    <Section title={"Photo Desktop"}>
      <FileInput type="file" accept="image/*" onChange={handlePhotoChange}/>
    </Section>

    <Section title={"Photo Mobile"}>
      <FileInput type="file" accept="image/*" onChange={handlePhotoMobileChange}/>
    </Section>

    <AddButton type="button" onClick={handleSubmit}>Update</AddButton>
    <MessagePopup
      show={popup.show}
      message={popup.message}
      success={popup.success}
      onButtonClick={handleRedirect}
    />
  </Container>);
}
