import styled from "styled-components";
import React from "react";

const fontSize = '16px';

const PopupWrapper = styled.div`
    position: fixed;
    bottom: 20px;
    right: 20px;
    padding: 20px;
    width: 300px;
    background-color: rgba(19, 19, 19, 0.9);
    color: white;
    border: none;
    border-bottom: 2px solid red;
    border-radius: 10px;
    z-index: 1000;
    opacity: ${({show}) => (show ? '1' : '0')};
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    transform: ${({show}) => (show ? 'translateY(0)' : 'translateY(20px)')};
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: ${fontSize};
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
`;

const PopupButton = styled.button`
    margin-top: 20px;
    padding: 10px 20px;
    background: transparent;
    color: white;
    border: none;
    border-bottom: 2px solid red;
    cursor: pointer;
    font-size: ${fontSize};
    outline: none;
`;

export default function MessagePopup({show, success, message, onButtonClick}) {
  return (<PopupWrapper show={show} success={success}>
    {message}
    {success && (<PopupButton onClick={onButtonClick}>Vai alla pagina</PopupButton>)}
  </PopupWrapper>);
}