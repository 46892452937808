import {handleGetRequest, handlePostRequest} from "./handleRequest";

const apiUrl = process.env.REACT_APP_API_URL;

export async function getFooterData() {
  try {
    const url = `${apiUrl}/lb/footer/?action=getVisible`;
    return await handleGetRequest(url);
  } catch (error) {
    throw error;
  }
}

export async function addFooterData(data) {
  try {
    return await handlePostRequest(`${process.env.REACT_APP_API_URL}/lb/footer/`, {
      data: data, action: "new"
    });
  } catch (error) {
    throw error;
  }
}