import React, {useEffect, useState} from 'react';
import {getAllUnitOfMeasure} from "../../api/UnitMeasure";
import SettingsTable from "../../packages/my-theme/component/SettingsTable";
import LoadingSpinner from "../../packages/my-theme/component/LoadingSpinner";

export default function UnitMeasureComponent() {
  const [unitMeasure, setUnitMeasure] = useState(null);

  useEffect(() => {
    getAllUnitOfMeasure()
      .then(data => {

        const content = data
          .map(unitMeasure => ({
            index: unitMeasure.id, data: [unitMeasure.name, unitMeasure.symbol],
          }))
          .sort((a, b) => a.data[0].localeCompare(b.data[0]));

        setUnitMeasure(content);
      })
      .catch(err => {
        console.error("Errore durante il recupero delle unità di misura:", err);
      });
  }, []);

  if (unitMeasure == null) {
    return <LoadingSpinner/>;
  }

  const handleEdit = (id) => {
    console.log(`Modifica unità di misura con ID: ${id}`);
  };

  const handleRemove = (id) => {
    console.log(`Rimuovi unità di misura con ID: ${id}`);
  };

  return (<SettingsTable
    content={unitMeasure}
    handleEdit={handleEdit}
    handleRemove={handleRemove}
    columnNames={["Nome", "Simbolo"]}
    tableName={"Unità di Misura"}
    newLink={"/settings/newUnit"}
  />);
}