import {handleGetRequest, handlePostRequest} from "./handleRequest";

const apiUrl = process.env.REACT_APP_API_URL;

export async function getContactData() {
  try {
    const url = `${apiUrl}/lb/contact/?action=getVisible`;
    return await handleGetRequest(url);
  } catch (error) {
    throw error;
  }
}

export async function addContactData(data) {
  try {
    return await handlePostRequest(`${process.env.REACT_APP_API_URL}/lb/contact/`, {
      data: data, action: "new"
    });
  } catch (error) {
    throw error;
  }
}