import {handleGetRequest} from "./handleRequest";

const apiUrl = process.env.REACT_APP_API_URL;

export default async function fetchToken() {
  try {
    const url = `${apiUrl}/token/?action=token`;
    return await handleGetRequest(url);
  } catch (error) {
    throw error;
  }
}
