import React from 'react';
import styled from 'styled-components';
import ThemeProviderComponent from "./ThemeProvider";
import {AppContainer, AppHeader} from "./styled";

const LayoutContainer = styled.div`
    display: flex;
    background-color: #131313;
`;

const Content = styled.div`
    flex: 1;
    margin-left: 250px;
    padding: 20px;
`;

export default function Layout({children, sidebar}) {
  return (<ThemeProviderComponent>
    <LayoutContainer>
      {sidebar}
      <Content>
        <AppContainer>
          <AppHeader>
            {children}
          </AppHeader>
        </AppContainer>
      </Content>
    </LayoutContainer>
  </ThemeProviderComponent>);
};
