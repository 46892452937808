import React, { useRef, useEffect } from 'react';
import styled from "styled-components";
import {fontSize} from "./styled";

const StyledTextArea = styled.textarea`
    display: block;
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    box-sizing: border-box;
    font-size: ${fontSize};
    color: white;
    border: none;
    border-bottom: 2px solid red;
    background: transparent;
    outline: none;
    resize: none;
    overflow: hidden;
`;

export default function TextArea({ value, placeholder, onChange }) {
  const textAreaRef = useRef(null);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = 'auto'; // Reset altezza
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`; // Imposta l'altezza in base al contenuto
    }
  }, [value]);

  return (
    <StyledTextArea
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      ref={textAreaRef}
      onInput={() => {
        textAreaRef.current.style.height = 'auto'; // Reset altezza
        textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`; // Imposta l'altezza in base al contenuto
      }}
    />
  );
}