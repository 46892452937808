import styled from 'styled-components';

export const Title = styled.h1`
    font-size: 64px;
    color: #FF2E2E;
    @media (max-width: 768px) {
        text-align: center;
    }
`;

export const AppContainer = styled.div`
  padding: 1%;
  width: 95%; 
`;

export const AppHeader = styled.header`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: calc(10px + 2vmin);

    background-color: ${(props) => props.theme.colors.backgroundColor};
    color: #FF2E2E;
`;

const backgroundImageUrl = 'https://liquorebonito.it/background/background.jpg';

export const FullPhotoBackground = styled.div`
    background-image: url(${backgroundImageUrl});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    text-align: right;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 80px;

    @media (max-width: 1000px) {
        padding: 0 40px;
    }

    @media (max-width: 768px) {
        padding: 0 20px;
    }
`;

export const GrayContainer = styled.div`
    width: 40%;
    height: auto%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 15px;

    @media (max-width: 1000px) {
        height: 45%;
        width: 60%;
    }

    @media (max-width: 768px) {
        height: 70%;
        width: 80%;
    }
`;