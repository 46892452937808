import {handleGetRequest, handlePostRequest} from "./handleRequest";

const apiUrl = process.env.REACT_APP_API_URL;

export async function getHomeData() {
  try {
    const url = `${apiUrl}/lb/home/?action=getVisible`;
    return await handleGetRequest(url);
  } catch (error) {
    throw error;
  }
}

export async function addHomeData(data) {
  try {
    return await handlePostRequest(`${process.env.REACT_APP_API_URL}/lb/home/`, {
      data: data, action: "new"
    });
  } catch (error) {
    throw error;
  }
}
