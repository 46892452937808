import React from 'react';
import styled from 'styled-components';

const SectionWrapper = styled.div`
    margin-bottom: 20px;
    padding: 20px;
    background: #1e1e1e;
    border-radius: 5px;
`;

const SectionTitle = styled.h2`
    font-size: 18px;
    color: white;
    margin-bottom: 10px;
`;

export default function Section({title, children}) {
  return (
    <SectionWrapper>
      {title && <SectionTitle>{title}</SectionTitle>}
      {children}
    </SectionWrapper>
  );
};
