import React, {useEffect, useState} from 'react';
import {getAllIngredients} from '../../api/Ingredient';
import SettingsTable from "../../packages/my-theme/component/SettingsTable";
import LoadingSpinner from "../../packages/my-theme/component/LoadingSpinner";

export default function Ingredients() {
  const [ingredients, setIngredients] = useState(null);

  useEffect(() => {
    getAllIngredients()
      .then(data => {
        const content = data
          .map(ingredient => ({
            index: ingredient.id, data: [ingredient.name],
          }))
          .sort((a, b) => a.data[0].localeCompare(b.data[0]));

        setIngredients(content);
      })
      .catch(err => {
        console.error("Errore durante il recupero degli ingredienti:", err);
      });
  }, []);

  if (ingredients == null) {
    return <LoadingSpinner/>;
  }

  const handleEdit = (id) => {
    console.log(`Modifica ingrediente con ID: ${id}`);
  };

  const handleRemove = (id) => {
    console.log(`Rimuovi ingrediente con ID: ${id}`);
  };

  return (<SettingsTable
    content={ingredients}
    handleEdit={handleEdit}
    handleRemove={handleRemove}
    columnNames={["Nome"]}
    tableName={"Ingredienti"}
    newLink={"/settings/newIngredient"}
  />);
}
