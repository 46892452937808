import React from 'react';
import {FaEdit, FaTrash} from 'react-icons/fa';
import styled from 'styled-components';
import {Title} from "../styled";
import {Link} from 'react-router-dom';

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
`;

const TableHeader = styled.th`
    padding: 10px;
    border-bottom: 1px solid #ddd;
`;

const TableCell = styled.td`
    padding: 10px;
    border-bottom: 1px solid #ddd;
`;

const CenteredTableCell = styled(TableCell)`
    text-align: center;
    vertical-align: middle;
`;

const IconContainer = styled.div`
    display: flex;
    justify-content: center;
    gap: 8px;
`;

const EditIcon = styled(FaEdit)`
    cursor: pointer;
    color: blue;

    &:hover {
        color: darkblue;
    }
`;

const TrashIcon = styled(FaTrash)`
    cursor: pointer;
    color: red;

    &:hover {
        color: darkred;
    }
`;

const ButtonContainer = styled.div`
    text-align: right;
    margin-bottom: 20px;
`;

const NewButton = styled(Link)`
    background-color: #FF2E2E;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    text-decoration: none;
    display: inline-block;
    margin-bottom: 20px;

    &:hover {
        background-color: darkred;
    }
`;

export default function SettingsTable({content, handleEdit, handleRemove, columnNames, tableName, newLink}) {
  return (
    <>
      <Title>{tableName}</Title>
      <ButtonContainer>
        <NewButton to={newLink}>Nuovo</NewButton>
      </ButtonContainer>
      <Table>
        <thead>
        <tr>
          {columnNames.map((columnName, index) => (
            <TableHeader key={index}>{columnName}</TableHeader>
          ))}
          <TableHeader>Azione</TableHeader>
        </tr>
        </thead>
        <tbody>
        {content.map(item => (
          <tr key={item.index}>
            {item.data.map((columnData, index) => (
              <TableCell key={index}>{columnData}</TableCell>
            ))}
            <CenteredTableCell>
              <IconContainer>
                <EditIcon onClick={() => handleEdit(item.index)}/>
                <TrashIcon onClick={() => handleRemove(item.index)}/>
              </IconContainer>
            </CenteredTableCell>
          </tr>
        ))}
        </tbody>
      </Table>
    </>
  );
}
