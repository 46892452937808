import React from 'react';
import { useRouteError } from "react-router-dom";
import ErrorPage from "../../packages/my-theme/component/ErrorPage";
import {Title} from "../../packages/my-theme/styled";

export default function RouteErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <ErrorPage>
      <Title>La Pagina Non Esiste</Title>
    </ErrorPage>
  );
}
