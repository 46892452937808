import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

import {FaHome, FaUser, FaCog, FaSignOutAlt} from 'react-icons/fa';
import {handleLogout} from "../api/Authentication";

const SidebarContainer = styled.div`
    height: 100vh;
    background-color: #131313;
    padding: 20px;
    color: #FF2E2E;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 0;
    top: 0;
    width: 5%;
`;

const MenuList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
`;

const MenuItem = styled.li`
    margin-bottom: 10px;
    width: 100%;
    display: flex;
    align-items: center;
`;

const LogoutMenuItem = styled(MenuItem)`
    margin-top: 50px; /* Spaziatura extra sopra il pulsante di logout */
`;

const StyledLink = styled(Link)`
    text-decoration: none;
    color: #FF2E2E;
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 22px;
`;

const IconWrapper = styled.div`
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const LogoutButton = styled.button`
    background: none;
    border: none;
    color: #FF2E2E;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    width: 100%;
    text-align: left;
    font-size: 22px;
`;

export default function Sidebar() {

  return (<SidebarContainer>
    <MenuList>
      <MenuItem>
        <StyledLink to="/frontendData">
          <IconWrapper>
            <FaHome/>
          </IconWrapper>
          FrontendData
        </StyledLink>
      </MenuItem>
      <MenuItem>
        <StyledLink to="/cocktails">
          <IconWrapper>
            <FaUser/>
          </IconWrapper>
          Cocktails
        </StyledLink>
      </MenuItem>
      <MenuItem>
        <StyledLink to="/settings">
          <IconWrapper>
            <FaCog/>
          </IconWrapper>
          Settings
        </StyledLink>
      </MenuItem>
      <LogoutMenuItem>
        <LogoutButton onClick={handleLogout}>
          <IconWrapper>
            <FaSignOutAlt/>
          </IconWrapper>
          LogOut
        </LogoutButton>
      </LogoutMenuItem>
    </MenuList>
  </SidebarContainer>);
}
