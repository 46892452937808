// src/components/ProtectedRoute.js
import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { getSessionToken } from "../api/Cookies";
import LoadingSpinner from "../packages/my-theme/component/LoadingSpinner";

export default function ProtectedRoute({ element }) {
  const [isAuthenticated, setAuth] = useState(null);

  useEffect(() => {
    getSessionToken()
      .then(data => {
        if (data.value == null) {
          setAuth(false);
        } else {
          setAuth(true);
        }
      })
      .catch(err => {
        setAuth(false);
      });
  }, []);

  if (isAuthenticated === null) {
    return <LoadingSpinner />
  }

  if (isAuthenticated) {
    return element;
  } else {
    return <Navigate to="/login" />;
  }
}
