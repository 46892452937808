import {handleGetRequest, handlePostRequest} from "./handleRequest";

const apiUrl = process.env.REACT_APP_API_URL;

export async function getAbout(){
  try{
    return await handleGetRequest(`${apiUrl}/lb/about/?action=getVisible`);
  }
  catch (error) {
    throw error;
  }
}

export async function addAboutText(data) {
  try {
    return await handlePostRequest(`${process.env.REACT_APP_API_URL}/lb/about/`, {
      data: data, action: "new"
    });
  } catch (error) {
    throw error;
  }
}