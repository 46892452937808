import React, {useState} from 'react';
import {addUnitOfMeasure} from "../../api/UnitMeasure";
import {useNavigate} from "react-router-dom";
import {Container} from "../../packages/my-theme/form/styled";
import {Title} from "../../packages/my-theme/styled";
import Section from "../../packages/my-theme/form/Section";
import TextArea from "../../packages/my-theme/form/TextArea";
import AddButton from "../../packages/my-theme/form/AddButton";
import MessagePopup from "../../packages/my-theme/component/MessagePopup";

export default function NewUnitMeasure() {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [symbol, setSymbol] = useState('');
  const [popup, setPopup] = useState({show: false, message: '', success: false});

  const handleSubmit = async () => {
    const unitData = {name, symbol};

    try {
      const response = await addUnitOfMeasure(unitData);
      if (response.message === "Unità di misura aggiunta con successo") {
        setPopup({show: true, message: 'Unità di misura aggiunta con successo!', success: true});
      } else {
        throw new Error('Errore durante l\'aggiunta dell\'unità di misura');
      }
    } catch (err) {
      setPopup({show: true, message: 'Errore durante l\'aggiunta dell\'unità di misura', success: false});
    }
  };

  const handlePopupClose = () => {
    navigate('/settings/units');
  };

  return (
    <Container>
      <Title>New Unit of Measure</Title>
      <Section title="">
        <TextArea
          placeholder="Unit Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextArea
          placeholder="Unit Symbol"
          value={symbol}
          onChange={(e) => setSymbol(e.target.value)}
        />
      </Section>

      <AddButton type="button" onClick={handleSubmit}>
        Submit
      </AddButton>

      <MessagePopup
        show={popup.show}
        message={popup.message}
        success={popup.success}
        onButtonClick={handlePopupClose}
      />
    </Container>
  );
}
