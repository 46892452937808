import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter, RouterProvider,
} from "react-router-dom";
import './index.css';
import reportWebVitals from './reportWebVitals';
import Root from "./routes/root";
import RouteErrorPage from "./pages/error/RouteErrorPages";
import AddCocktail from "./pages/sidebar/AddCocktail";
import Cocktails from "./pages/sidebar/Cocktails";
import FrontendData from "./pages/sidebar/FrontendData";
import Settings from "./pages/sidebar/Settings";
import LoginForm from "./pages/LoginForm";
import NewUnitMeasure from "./pages/sidebar/NewUnitMeasure";
import NewIngredient from "./pages/sidebar/NewIngredient";
import Ingredients from "./pages/sidebar/Ingredients";
import UnitMeasureComponent from "./pages/sidebar/UnitMeasure";
import ProtectedRoute from "./components/ProtectedRoutes";
import Home from "./pages/frontendSubpages/Home";
import About from "./pages/frontendSubpages/About";
import Contact from "./pages/frontendSubpages/Contact";
import Footer from "./pages/frontendSubpages/Footer";
import Sidebar from "./components/Sidebar";
import Layout from "./packages/my-theme/Layout";
import CocktailText from "./pages/frontendSubpages/CocktailText";
import EditCocktail from "./pages/sidebar/EditSidebar";

const router = createBrowserRouter([{
  path: "/", element: (<Layout sidebar={<Sidebar/>}>
    <Root/>
  </Layout>), errorElement: <RouteErrorPage/>,
}, {
  path: "/login", element: (<LoginForm/>), errorElement: <RouteErrorPage/>,
}, {
  path: "/addCocktail", element: (<Layout sidebar={<Sidebar/>}>
    <AddCocktail/>
  </Layout>), errorElement: <RouteErrorPage/>,
}, {
  path: "/cocktails",
  element: (<ProtectedRoute element={<Layout sidebar={<Sidebar/>}><Cocktails/></Layout>}/>),
  errorElement: <RouteErrorPage/>,
}, {
  path: "/frontendData", element: (<ProtectedRoute element={<Layout sidebar={<Sidebar/>}>
      <FrontendData/>
    </Layout>}/>

  ), errorElement: <RouteErrorPage/>,
}, {
  path: "/frontendData/home", element: (<ProtectedRoute element={<Layout sidebar={<Sidebar/>}>
      <Home/>
    </Layout>}/>

  ), errorElement: <RouteErrorPage/>,
},
  {
    path: "/cocktail/:cocktailId", element: (<ProtectedRoute element={<Layout sidebar={<Sidebar/>}>
        <EditCocktail/>
      </Layout>}/>

    ), errorElement: <RouteErrorPage/>,
  },
  {
    path: "/frontendData/cocktails", element: (<ProtectedRoute element={<Layout sidebar={<Sidebar/>}>
        <CocktailText/>
      </Layout>}/>

    ), errorElement: <RouteErrorPage/>,
  },
  {
    path: "/frontendData/about", element: (<ProtectedRoute element={<Layout sidebar={<Sidebar/>}>
        <About/>
      </Layout>}/>

    ), errorElement: <RouteErrorPage/>,
  }, {
    path: "/frontendData/contact", element: (<ProtectedRoute element={<Layout sidebar={<Sidebar/>}>
        <Contact/>
      </Layout>}/>

    ), errorElement: <RouteErrorPage/>,
  }, {
    path: "/frontendData/footer", element: (<ProtectedRoute element={<Layout sidebar={<Sidebar/>}>
        <Footer/>
      </Layout>}/>

    ), errorElement: <RouteErrorPage/>,
  }, {
    path: "/settings", element: (<ProtectedRoute element={<Layout sidebar={<Sidebar/>}>
      <Settings/>
    </Layout>}/>), errorElement: <RouteErrorPage/>,
  }, {
    path: "/settings/ingredients", element: (<ProtectedRoute element={<Layout sidebar={<Sidebar/>}>
      <Ingredients/>
    </Layout>}/>), errorElement: <RouteErrorPage/>,
  }, {
    path: "/settings/newIngredient", element: (<ProtectedRoute element={<Layout sidebar={<Sidebar/>}>
      <NewIngredient/>
    </Layout>}/>), errorElement: <RouteErrorPage/>,
  }, {
    path: "/settings/units", element: (<ProtectedRoute element={<Layout sidebar={<Sidebar/>}>
      <UnitMeasureComponent/>
    </Layout>}/>), errorElement: <RouteErrorPage/>,
  }, {
    path: "/settings/newUnit", element: (<ProtectedRoute element={<Layout sidebar={<Sidebar/>}>
      <NewUnitMeasure/>
    </Layout>}/>), errorElement: <RouteErrorPage/>,
  }]);

ReactDOM.createRoot(document.getElementById("root")).render(<React.StrictMode>
  <RouterProvider router={router}/>
</React.StrictMode>);

reportWebVitals();