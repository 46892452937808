import React, {useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {addContactData, getContactData} from "../../api/Contact";
import LoadingSpinner from "../../packages/my-theme/component/LoadingSpinner";
import {Container} from "../../packages/my-theme/form/styled";
import {Title} from "../../packages/my-theme/styled";
import Section from "../../packages/my-theme/form/Section";
import TextArea from "../../packages/my-theme/form/TextArea";
import AddButton from "../../packages/my-theme/form/AddButton";
import MessagePopup from "../../packages/my-theme/component/MessagePopup";

export default function Contact() {
  const navigate = useNavigate();
  const [email, setEmail] = useState(null);
  const [text, setText] = useState(null);
  const [phone, setPhone] = useState(null);
  const [popup, setPopup] = useState({show: false, message: '', success: false});
  const textAreaRefs = useRef([React.createRef(), React.createRef(), React.createRef()]);

  useEffect(() => {
    getContactData()
      .then(data => {
        setEmail(data[0].email);
        setPhone(data[0].phone);
        setText(data[0].text);
      })
      .catch(error => {
        console.error("Error fetching contact data:", error);
        setPopup({show: true, message: 'Errore nel recupero dei dati!', success: false});
      });
  }, []);

  const handleSubmit = () => {
    addContactData({email, phone, text, view: 1, orderIndex: 1})
      .then(response => {
        if (response.message === "Record ContactData inseriti con successo") {
          setPopup({show: true, message: 'Inserimento avvenuto con successo!', success: true});
        }
      })
      .catch(error => {
        console.log(error);
        setPopup({show: true, message: 'Errore durante l\'inserimento!', success: false});
      });
  };

  const handleRedirect = () => {
    navigate('/frontendData');
  };

  if (email == null || phone == null) {
    return <LoadingSpinner/>;
  }

  return (<Container>
    <Title>Contact</Title>
    <Section title="">
      <TextArea
        placeholder="Inserisci email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        ref={textAreaRefs.current[0]}
      />
      <TextArea
        placeholder="Inserisci telefono"
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
        ref={textAreaRefs.current[1]}
      />
      <TextArea
        placeholder="Inserisci testo"
        value={text}
        onChange={(e) => setText(e.target.value)}
        ref={textAreaRefs.current[2]}
      />
    </Section>
    <AddButton type="button" onClick={handleSubmit}>
      Submit
    </AddButton>
    <MessagePopup
      show={popup.show}
      message={popup.message}
      success={popup.success}
      onButtonClick={handleRedirect}
    />
  </Container>);
}
